:root {
  --ratio: 1.5;
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));
}

html {
  box-sizing: border-box;

  font-size: 16px;
  font-family: 'Lora', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}

body {
  margin: 0;
  width: 100%;
}

#root {
  overflow-x: hidden;
}

* {
  box-sizing: inherit;
}

.break-out-banner {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

.grecaptcha-badge { 
    visibility: hidden;
}

/* ========================= Component Styles ========================= */

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lora', serif;
}

.fancy-first::first-letter {
  font-size: 650%;
  line-height: 50%;
  
  float: left;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 1rem;
}

.text-responsive {
  font-size: clamp(100%, 3vw, 150%);
}

/* Use span or div if you don't need this spacing */
*+h1,
*+h2,
*+h3,
*+h4,
*+h5,
*+h6,
*+p {
  margin-top: 1em;
}

.tile-1 {
  clip-path: url(#tile-1);
}

@media screen and (min-width: 1024px) {
  .hide-above-lg {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) {
  .no-tile-lg {
    clip-path: none;
  }

  .hide-on-lg {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .hide-above-md {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .no-tile-md {
    clip-path: none;
  }

  .hide-on-md {
    display: none;
  }

  html {
    font-size: 12px;
  }
}